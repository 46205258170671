@media (min-width: 769px) {
  .process-row-left-orientated .three-purple-images img {
    width: 15%;
  }
  .three-salads-images {
    justify-content: space-around;
  }
  .three-salads-images img:nth-child(2) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .process-row-right-orientated .three-purple-images img {
    width: 15%;
  }
}

@media (max-width: 769px) {
  .hero h1 {
    font-size: 7rem;
  }
  .hero .nav {
    padding: 5rem 2rem;
  }
  .logo {
    height: 45px;
  }
  .hero {
    max-height: 100vh;
    background-attachment: initial;
  }
  .about {
    padding-top: 3rem;
    padding-left: 10%;
    padding-right: 10%;
  }
  .about h4 {
    font-size: 1rem;
  }

  .about h2 {
    font-size: 3.4rem;
    padding-bottom: 100px;
  }
  .facts {
    flex-direction: column;
    align-items: center;
    padding: 0px !important;
  }
  .facts .circleContainer {
    padding-bottom: 100px;
    width: 340px !important;
  }
  .circleContainer img {
    width: 130px;
    height: 130px;
  }
  .facts .circleContainer h3 {
    font-size: 2.8rem;
    padding: 0.7rem 0px;
  }

  .facts .circleContainer p {
    max-width: 86%;
  }
  .people-saying {
    margin-bottom: 0px;
  }
  .people-saying h2 {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 3rem;
    padding-bottom: 14rem;
  }

  .people-saying h4 {
    font-size: 1rem;
    padding-bottom: 2rem;
    padding-top: 0px;
  }
  .kosher {
    padding: 47px 0px 47px 0px;
  }
  .process-row-left-orientated {
    padding-left: 10%;
    padding-right: 10%;
  }
  .process-row-left-orientated .process-row-left-orientated-right {
    padding-left: 0px;
    text-align: center;
    align-items: center;
  }
  .process-row-left-orientated .mix-description {
    text-align: center;
  }
  .first-bg-salad .package {
    padding: 0px;
  }
  .process-row-left-orientated .three-purple-images img {
    width: 90px;
    height: 90px;
  }

  .process-row-right-orientated {
    padding-left: 10%;
    padding-right: 10%;
  }
  .process-row-right-orientated .second-bg-salad {
    order: 1;
  }
  .process-row-right-orientated .process-row-right-orientated-left {
    order: 2;
    padding-left: 0px;
    align-items: center;
  }

  .mix-description {
    text-align: center !important;
  }
  .three-purple-images img {
    width: 90px;
    height: 90px;
  }
  .mix-title {
    font-size: 4rem !important;
    padding-top: 0px !important;
  }

  .package {
    width: 60% !important;
    height: 100% !important;
    padding: 0px !important;
  }

  .kosher {
    padding-left: 10%;
    padding-right: 10%;
  }
  .kosher h2 {
    font-size: 4.5rem;
  }

  .kosher h4 {
    font-size: 2.2rem;
  }

  .three-salads {
    padding-left: 10%;
    padding-right: 10%;
  }
  .three-salads img {
    width: 280px;
    margin-bottom: 30px;
  }

  .three-salads h2 {
    font-size: 4rem;
    padding-top: 2rem;
  }

  .three-salads-images {
    margin-top: 59px;
  }

  .promise {
    padding-left: 10%;
    padding-right: 10%;
  }

  .promise h2 {
    text-align: center;
    font-size: 4rem;
  }

  .promise h4 {
    text-align: center;
    font-size: 2rem;
  }

  .promise p {
    text-align: center;
  }

  .promise-right {
    align-items: center;
  }

  .under-footer-logo-text {
    width: 100%;
    padding-bottom: 30px;
  }

  .footer-left {
    width: 100%;
    padding-left: 0px;
  }

  .footer-right {
    flex-direction: column;
  }

  .footer-right div {
    padding-bottom: 30px;
  }

  footer {
    padding-left: 10%;
    padding-right: 10%;
  }

  footer .copyright {
    padding-left: 0px !important;
    padding-top: 0px;
  }

  .hero .nav {
    padding: 3rem 2rem;
  }
  .br {
    display: none;
  }

  .process-row-left-orientated,
  .process-row-right-orientated {
    flex-direction: column;
  }
  .process-row-right-orientated-left,
  .process-row-right-orientated-right,
  .process-row-left-orientated-left,
  .process-row-left-orientated-left,
  .process-row-left-orientated-right {
    width: 100% !important;
  }
  .three-salads-images {
    flex-direction: column;
  }
  .three-salads-images img:nth-child(2) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .three-salads-images {
    justify-content: center;
    align-items: center;
  }

  .promise-left,
  .promise-right {
    width: 100%;
  }
  .promise {
    flex-direction: column;
  }

  .footer-main {
    flex-direction: column;
  }
  .process-row-left-orientated .mix-title,
  .process-row-right-orientated .mix-title {
    padding-top: 25px !important;
  }

  .nav h3 {
    display: none;
  }
  .nav {
    justify-content: center !important;
    align-items: center;
  }

  footer {
    position: relative !important;
    height: auto !important;
  }
  .main-container {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) {
  .kosher {
    min-height: 56vh !important;
    padding: 0px;
  }
}
