
@media only screen and (max-width: 769px) {
    .logo{
        display: none;
    }
    .menu-button{
        font-family: 'GT-America-Mono-Regular';
        cursor: pointer;
        position: fixed;
        font-size: 18px;
        z-index: 400;
        color: #fff;
        height: 85px;
        width: 85px;
        background-color: #375239;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        top: 25px;
        left: 30px;
    }  
    .menu-button-close{
        color: #1D1D1D;
        background-color: #EAE7E1;
    }
    .menu-open{
        height: 100vh;
        width: 100%;
        position: fixed;
        background-color: #fff;
        z-index: 300;
        opacity: 1;
        transform: translateX(-100%);
        transition: transform .3s ease-in;
    }
    .menu-open-active{
        transform: translateX(0);
    }
    .menu-open-elements{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 42px;
        /* line-height: 62.54px; */
        font-weight: 400;
        font-family: 'Ogg-regular';
    }
    .menu-open-elements >div a{
        cursor: pointer;
        color: #000;
    }
    .image-salad{
        max-width: 102px;
        margin-bottom: 21px;
    }
    
  }
@media only screen and (min-width: 770px) {
    .menu-button{
    display: none;
    }
    .menu-open{
        display: none;
    }
}