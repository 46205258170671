body,
html,
* {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

.logo {
  height: 60px;
}

/*Fonts*/
@font-face {
  font-family: "Arial";
  src: local("arial"), url(./fonts/Arial.ttf);
}

@font-face {
  font-family: "GT-America-Extended-Medium";
  src: local("GT-America-Condensed-Bold"),
    url(./fonts/GT-America-Condensed-Bold.OTF);
}

@font-face {
  font-family: "GT-America-Extended-Medium";
  src: local("GT-America-Extended-Medium"),
    url(./fonts/GT-America-Extended-Medium.OTF);
}

@font-face {
  font-family: "GT-America-Light";
  src: local("GT-America-Light"), url(./fonts/GT-America-Light.OTF);
}

@font-face {
  font-family: "GT-America-Medium";
  src: local("GT-America-Medium"), url(./fonts/GT-America-Medium.OTF);
}

@font-face {
  font-family: "GT-America-Mono-Light";
  src: local("GT-America-Mono-Light"), url(./fonts/GT-America-Mono-Light.OTF);
}

@font-face {
  font-family: "GT-America-Mono-Regular";
  src: local("GT-America-Mono-Regular"),
    url(./fonts/GT-America-Mono-Regular.OTF);
}

@font-face {
  font-family: "GT-America-Regular";
  src: local("GT-America-Regular"), url(./fonts/GT-America-Regular.OTF);
}

@font-face {
  font-family: "GT-America-Thin";
  src: local("GT-America-Thin"), url(./fonts/GT-America-Thin.OTF);
}

@font-face {
  font-family: "Ogg-roman";
  src: local("Ogg-roman"), url(./fonts/Ogg\ Roman.otf);
}

@font-face {
  font-family: "Ogg-regular";
  src: local("Ogg-regular"), url(./fonts/Ogg-Regular.otf);
}

@font-face {
  font-family: "Ogg-regularItalic";
  src: local("Ogg-regularItalic"), url(./fonts/Ogg-RegularItalic.otf);
}

.hero {
  /*  background-image: linear-gradient(
      to bottom,
      rgba(19, 19, 19, 0.4),
      rgba(19, 19, 19, 0.1)
    ),
    url('./assets/Hero.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-attachment: fixed;
  position: relative;
}

.hero .nav {
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 10rem;
  color: white;
  position: absolute;
}
.hero .nav h3 {
  font-family: "GT-America-Mono-Regular";
  font-size: 1.3rem;
  font-weight: 400;
  opacity: 0.6;
}

.hero h1 {
  width: 100%;
  font-size: 12rem;
  font-family: "Ogg-regular";
  max-width: 969px;
  color: white;
  text-align: center;
  font-weight: 400;
  line-height: 1;
}

.about {
  text-align: center;
  padding-bottom: 6.5rem;
  padding-top: 9.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.about img {
  height: 120px;
}

.about h4 {
  font-family: "GT-America-Regular";
  font-size: 1.4rem;
  padding: 2.5rem 0px;
  font-weight: 200;
}

.about h2 {
  font-family: "Ogg-Regular";
  font-size: 6.2rem;
  font-weight: 400;
  max-width: 720px;
  margin: 0 auto;
  line-height: 1;
  padding-bottom: 9%;
}

.facts {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  padding: 0px 10%;
}

.facts .circleContainer {
  width: 33.3333333%;
}

.facts .circleContainer h3 {
  font-family: "GT-America-Extended-Medium";
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  padding: 3rem 0px;
}
.facts .circleContainer p {
  font-family: "GT-America-Regular";
  font-size: 1.6rem;
  line-height: 1.5;
  max-width: 73%;
  margin: auto;
}

.people-saying {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
}

.people-saying h4 {
  font-family: "GT-America-Regular";
  font-size: 1.4rem;
  padding: 20rem 0px 4rem 0px;
  font-weight: 200;
}

.people-saying h2 {
  font-family: "Ogg-Regular";
  font-size: 5.2rem;
  font-weight: 400;
  margin: 0 auto;
  line-height: 1.3;
  padding-bottom: 19rem;
  max-width: 1086px;
}

.kosher {
  background-color: #5c1d47;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  padding: 240px 0px 240px 0px;
  background: url(./assets/kosherbg.jpg);
  background-size: cover;
  position: relative;
}

@media (min-width: 800px) {
  .kosher {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.kosher:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.kosher h2 {
  font-family: "Ogg-Regular";
  font-size: 6.2rem;
  font-weight: 400;
  color: white;
}
.kosher h4 {
  color: white;
  font-family: "Ogg-Regular";
  font-size: 3.6rem;
  font-weight: 100;
  margin: 0px auto;
  max-width: 720px;
  margin-top: 25px;
}
.kosher img {
  width: 90px;
  margin: 0px auto;
  margin-bottom: 30px;
}
.three-salads {
  background-color: #375239;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  justify-content: center;
  padding: 170px 0px 170px 0px;
}
.three-salads h4 {
  font-family: "GT-America-Regular";
  font-size: 1.4rem;
  font-weight: 200;
}

.three-salads h2 {
  font-family: "Ogg-Regular";
  font-size: 5.2rem;
  font-weight: 400;
  max-width: 1100px;
  padding-top: 40px;
  margin: 0 auto;
  line-height: 1.3;
}
.promise {
  padding: 170px 0px 170px 0px;
  display: flex;
  justify-content: space-between;
  background-color: #ece8e2;
}
.promise-left,
.promise-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.promise-right h2 {
  font-family: "Ogg-Regular";
  font-size: 6.2rem;
  max-width: 488px;
  line-height: 1;
  font-weight: 100;
}
.promise-right h4 {
  font-family: "Ogg-Regular";
  font-size: 3rem;
  max-width: 550px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: 100;
}
.promise-right p {
  font-family: "GT-America-Regular";
  font-size: 1.6rem;
  max-width: 550px;
  line-height: 1.5;
}
.promise-right p:nth-child(1) {
  padding-bottom: 30px;
}
.three-salads img {
  width: 360px;
}
.three-salads-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin: 125px auto 0px auto;
  width: 90%;
}
.three-salads-images img:nth-child(2) {
  margin-left: 100px;
  margin-right: 100px;
}

.promise-left-img {
  width: 60%;
  margin-left: 20%;
}
.promise-left-koser-img {
  padding-top: 30px;
  width: 200px;
}
.first-par {
  padding-bottom: 20px;
}
.process-row-left-orientated-left,
.process-row-left-orientated-right {
  width: 50%;
}

.process-row-left-orientated {
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
}
.process-row-left-orientated .process-row-left-orientated-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12%;
}
.process-row-left-orientated .mix-title {
  font-family: "Ogg-regular";
  font-size: 5rem;
  font-weight: 100;
}

.process-row-left-orientated .mix-shelf-life {
  font-size: 1.6rem;
  font-family: "GT-America-Regular";
  font-weight: 100 !important;
  padding-top: 13px;
}
.process-row-left-orientated .mix-description {
  font-size: 1.6rem;
  font-family: "GT-America-Regular";
  font-weight: 100 !important;
  padding-top: 13px;
  text-align: left;
  max-width: 326px;
  line-height: 1.5;
}
.process-row-left-orientated .process-row-mix1 {
  width: 750px;
  z-index: 1;
}

.process-row-left-orientated .process-row-pack1 {
  width: 600px;
  height: 619px;
  position: absolute;
  left: 150px;
  top: 60px;
  z-index: 2;
}
.process-row-left-orientated .three-purple-images {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
}
.process-row-left-orientated .three-purple-images img {
  width: 90px;
}

/*OD TUKA PUCNUVA VTORIOT ROW SO SALATIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
.process-row-right-orientated-left,
.process-row-right-orientated-right {
  width: 50%;
}
.process-row-right-orientated {
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
}
.process-row-right-orientated .process-row-right-orientated-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2%;
  align-items: flex-start;
  padding-left: 12%;
}
.process-row-right-orientated .mix-title {
  font-family: "Ogg-regular";
  font-size: 5rem;
  font-weight: 100;
  padding-top: 69px;
}

.process-row-right-orientated .mix-shelf-life {
  font-size: 1.6rem;
  font-family: "GT-America-Regular";
  font-weight: 100 !important;
  padding-top: 13px;
}
.process-row-right-orientated .mix-description {
  font-size: 1.6rem;
  font-family: "GT-America-Regular";
  font-weight: 100 !important;
  padding-top: 13px;
  text-align: left;
  max-width: 326px;
  line-height: 1.5;
}
.process-row-right-orientated .process-row-mix2 {
  width: 750px;
  z-index: 1;
}

.process-row-right-orientated .process-row-pack2 {
  width: 600px;
  height: 619px;
  position: absolute;
  right: 20%;
  top: 75px;
  z-index: 2;
}
.process-row-right-orientated .three-purple-images {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
}
.process-row-right-orientated .three-purple-images img {
  width: 90px;
}

.circle2 {
  margin: 0px 20px;
}

footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: black;
  color: white;
  font-family: "GT-America-Regular";
  position: fixed;
  bottom: 0;
  z-index: -1;
  height: 500px;
}
.main-container {
  z-index: 10;
  position: relative;
  margin-bottom: 500px;
  background-color: white;
}
footer .copyright {
  padding-left: 135px;
  padding-top: 70px;
  padding-bottom: 100px;
}

footer h5 {
  font-family: "GT-America-Extended-Medium";
  font-size: 1.5rem;
  padding-bottom: 10px;
}
footer p {
  font-size: 1.5rem;
}

.under-footer-logo-text {
  width: 40%;
}

.footer-left img {
  margin-bottom: 20px;
}
.footer-main {
  display: flex;
  background-color: black;
  color: white;
  padding: 100px 0px 10px 0px;
}

.footer-left {
  width: 50%;
  padding-left: 135px;
  line-height: 1.5;
}
.footer-right {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-start;
  line-height: 1.5;
}

.footer-right div {
  padding-right: 10%;
}

a {
  color: white;
  text-decoration: none;
}

@media (min-width: 1919px) and (max-width: 1921px) {
  .child1 {
    left: 142px !important;
    top: 47px !important;
  }
  .process-row-right-orientated .process-row-pack2 {
    right: 19% !important;
  }
  .process-row-left-orientated .process-row-pack1:nth-child(2) {
    left: 190px;
    top: 55px !important;
  }
}

@media (min-width: 1439px) and (max-width: 1441px) {
  .child1 {
    left: 41px !important;
    top: 47px !important;
  }
  .process-row-right-orientated .process-row-pack2 {
    right: 7% !important;
  }
  .process-row-left-orientated .process-row-pack1:nth-child(2) {
    left: 42px !important;
    top: 55px !important;
  }
}
.first-bg-salad {
  background-image: url(./assets/mix1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.second-bg-salad {
  background-image: url(./assets/2-salad-bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.third-bg-salad {
  background-image: url(./assets/3-salad-bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.first-bg-salad .package {
  height: 100%;
  width: 70%;
  padding: 7.5% 9% 0% 0%;
}
.second-bg-salad .package {
  height: 100%;
  width: 66%;
  padding: 6.5% 3% 0% 0%;
}
.third-bg-salad .package {
  height: 100%;
  width: 66%;
  padding: 6.5% 3% 0% 0%;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.image_container {
  overflow: hidden;
}

.three-salads-images img {
  transition: transform 0.5s ease;
  overflow: hidden;
}
.three-salads-images img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.certification-margin {
  margin-top: 40px;
  font-style: italic;
}

.certification-margin-kosher {
  margin-top: 80px;
  font-style: italic;
  font-size: 18px;
  color: white;
  font-family: "GT-America-Regular";
}

.our-greens-button {
  color: white !important;
  font-size: 20px;
  background-color: #4ea350;
  padding: 20px 30px;
  text-decoration: none;
  border-radius: 50px;
  margin-top: 50px;
  font-family: "GT-America-Extended-Medium";
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.our-greens-button:hover,
.our-greens-button-black:hover {
  opacity: 0.9;
}
.our-greens-button-black {
  color: white !important;
  font-size: 20px;
  background-color: black;
  padding: 20px 30px;
  text-decoration: none;
  border-radius: 50px;
  margin-top: 50px;
  font-family: "GT-America-Extended-Medium";
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.three-purple-images {
  display: none !important;
}

.menu-left-container {
  display: flex;
  gap: 30px;
}
